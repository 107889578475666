<template>
  <v-card
    id="faulty-meters"
    class="mt-4"
  >
    <v-card-title>
      Faulty Meters
      <v-col class="col-md-1">
        <refresh-button
          :loading="isMetersRefreshing"
          @click="refreshMeters()"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="col-md-4">
        <search-input v-model="search" />
      </v-col>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="meters"
      :loading="isMetersLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear
          indeterminate
          class="mt-2"
          rounded
        ></v-progress-linear>
        <p class="text-center mt-1">
          Loading...Please wait
        </p>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="meters.length > 0">
          <tr
            v-for="faulty_meter in items"
            :key="faulty_meter.id"
          >
            <td>
              <span
                v-if="faulty_meter.meter.user"
                class="primary--text font-weight-medium"
              >{{
                faulty_meter.meter.user.account_number
              }}</span>
            </td>
            <td>
              <span
                v-if="faulty_meter.meter.user"
                class="primary--text font-weight-medium"
              >{{
                faulty_meter.meter.user.name
              }}</span>
            </td>
            <td>{{ faulty_meter.meter.number }}</td>
            <td>{{ faulty_meter.meter.last_communication_date | formatDate }}</td>
            <td>{{ faulty_meter.meter.battery_voltage }}</td>
            <td>{{ faulty_meter.meter.signal_intensity }}</td>
            <td>{{ faulty_meter.fault_type | formatReason }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-show="!isMetersLoading">
            <td
              :colspan="headers.length"
              style="text-align: center"
            >
              No meters found
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-divider />
    <data-table-pagination
      :page="pagination.page"
      :page-count="pagination.pageCount"
      @page-change="onPageChange"
      @items-per-page-change="onItemsPerPageChange"
    />
  </v-card>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiClipboardAccount,
} from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'

export default {
  components: {
    SearchInput,
    DataTablePagination,
    RefreshButton,
  },
  filters: {
    formatReason(value) {
      let reason
      switch (value) {
        case 1:
          reason = 'Delayed Communication'

          break
        case 2:
          reason = 'Low Battery'

          break

        default:
          reason = 'Unknown'
          break
      }

      return reason
    },
  },
  data() {
    return {
      sortBy: ['unresolved_mpesa_transactions.created_at'],
      sortDesc: [true],
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isMetersRefreshing: false,
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: 'Account Number', value: 'account_number' },
        { text: 'Name', value: 'name' },
        {
          text: 'Meter Number',
          align: 'start',
          value: 'number',
          sortable: false,
        },
        { text: 'Last communication date', value: 'last_communication_date' },
        { text: 'Battery voltage', value: 'battery_voltage' },
        { text: 'Signal intensity', value: 'signal_intensity' },
        { text: 'Fault type', value: 'fault' },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiClipboardAccount,
      },
      id: '',
      isMetersLoading: false,
      assignTransactionDialog: false,
      meters: [],
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getFaultyMeters()
    },
  },
  mounted() {
    this.getFaultyMeters()
  },
  methods: {
    getFaultyMeters: _.debounce(function () {
      this.isMetersLoading = true
      axios
        .get(
          `faulty-meters?sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`,
        )
        .then(response => {
          this.meters = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isMetersLoading = false
          this.isMetersRefreshing = false
        })
        .catch(error => {
          this.isMetersLoading = false
          this.isMetersRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    refreshMeters() {
      this.isMetersRefreshing = true
      this.getFaultyMeters()
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getFaultyMeters()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getFaultyMeters()
    },
  },
}
</script>

<style scoped>
.invalidAccountNumber {
  border-left: 1px solid #ffaa2c;
}
.noMeterReading {
  border-left: 1px solid #ff4c51;
}
.invalidCustomer {
  border-left: 1px solid #ed2cff;
}
</style>
