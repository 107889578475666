<template>
  <v-card min-height="400">
    <v-card-title>
      <v-col cols="12">
        Water usage
      </v-col>
      <v-col
        v-show="!isLoading && tabs.length > 0"
        cols="12"
      >
        <v-tabs
          v-model="activeTab"
          show-arrows
          @change="onTabChange"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col></v-col>
      <v-spacer></v-spacer>
      <v-col
        v-show="!isLoading && tabs.length > 0"
        class="mb-0 pb-0 text-right"
        cols="3"
      >
        <v-overflow-btn
          v-model="selected_filter"
          class="mb-0 pb-0"
          :items="filters"
          item-text="name"
          item-value="value"
          label="Filter"
          dense
        ></v-overflow-btn>
      </v-col>
    </v-card-title>
    <div
      v-show="!isLoading && tabs.length > 0"
      id="chart"
    >
      <apexchart
        ref="chart"
        type="line"
        height="400"
        :options="chartOptions"
        :series="chartOptions.series"
      ></apexchart>
    </div>
    <v-card-text v-show="!isLoading && tabs.length === 0">
      <p class="ml-4 font-weight-light">
        There is no enough data. Statistics will be displayed when enough data is gathered
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      selected_filter: 'last-7-days',
      activeTab: '',
      tabs: [],
      allReadings: [],
      filters: [
        {
          name: 'Last 7 days',
          value: 'last-7-days',
        },
        {
          name: 'Monthly',
          value: 'monthly',
        },
      ],
      chartOptions: {
        series: [{
          name: 'Units consumed',
          data: [],
        }],
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: [],
        },
      },

    }
  },
  watch: {
    selected_filter() {
      this.getReadings()
    },
  },
  mounted() {
    this.getReadings()
  },
  methods: {
    getReadings() {
      this.isLoading = true
      axios
        .get(`statistics/main-meter-readings?filter=${this.selected_filter}`)
        .then(response => {
          const { data } = response
          this.getData(data)
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.error(error)
          this.$notification.error(error.response.data.message)
        })
    },
    getData(data) {
      // eslint-disable-next-line camelcase
      const { main_meter_readings } = data
      // eslint-disable-next-line camelcase
      const { per_station_average_readings } = data

      const perStationReadings = []

      main_meter_readings.forEach(element => {
        const mainMeterData = {}
        mainMeterData.name = element.name
        mainMeterData.mainMeterReadings = element.readings
        perStationReadings.push(mainMeterData)
        this.tabs.push(element.name)
        this.sortTabs()
      })

      per_station_average_readings.forEach(element => {
        const averageReadingsData = {}
        averageReadingsData.name = element.name
        averageReadingsData.perStationAverageReadings = element.readings
        perStationReadings.push(averageReadingsData)
      })
      this.allReadings = this.reformatReadings(perStationReadings)
    },
    reformatReadings(data) {
      return data.reduce((acc, { name, mainMeterReadings, perStationAverageReadings }) => {
        acc[name] ??= { name, perStationAverageReadings: [], mainMeterReadings: [] }
        if (Array.isArray(mainMeterReadings)) { acc[name].mainMeterReadings = acc[name].mainMeterReadings.concat(mainMeterReadings) } else if (mainMeterReadings !== undefined) { acc[name].mainMeterReadings.push(mainMeterReadings) }

        if (Array.isArray(perStationAverageReadings)) { acc[name].perStationAverageReadings = acc[name].perStationAverageReadings.concat(perStationAverageReadings) } else if (perStationAverageReadings !== undefined) { acc[name].perStationAverageReadings.push(perStationAverageReadings) }

        return acc
      }, {})
    },
    setChartData(data) {
      const chartLabels = []
      const { mainMeterReadings } = data
      const { perStationAverageReadings } = data

      let mainMeterChartValues = []
      let perStationAverageChartValues = []

      const mainMeterAllReadings = []
      const perStationAverageAllReadings = []

      mainMeterReadings.forEach(element => {
        mainMeterAllReadings.push(element.reading)
        chartLabels.push(element.label)
      })

      perStationAverageReadings.forEach(element => {
        perStationAverageAllReadings.push(element.reading)
      })
      mainMeterChartValues = this.differenceBetweenArrayNumbers(mainMeterAllReadings)
      perStationAverageChartValues = this.differenceBetweenArrayNumbers(perStationAverageAllReadings)
      chartLabels.shift()

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: chartLabels,
          },
          series: [
            {
              name: 'Main Meter (Units consumed)',
              data: mainMeterChartValues,
            },
            {
              name: 'Customers Average (Units consumed)',
              data: perStationAverageChartValues,
            }],
        },
      }
    },
    searchStationReadings(stationArray, word) {
      const search = what => stationArray.find(element => element.name === what)
      const found = search(word)
      if (found) {
        return found.readings
      }

      return null
    },
    differenceBetweenArrayNumbers(array) {
      const differenceArray = []
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < array.length; i++) {
        differenceArray.push(Math.abs(array[i] - array[i - 1]))
      }

      return differenceArray
    },
    sortTabs() {
      this.tabs = this.tabs.sort((a, b) => {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }

        return 0
      })
    },
    onTabChange(index) {
      const selectedTabValue = this.tabs[index]
      const selectedStationReadings = this.allReadings[selectedTabValue]

      this.setChartData(selectedStationReadings)
    },
  },

}
</script>

<style>

</style>
