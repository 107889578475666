<template>
  <v-card height="475">
    <v-card-title class="align-start">
      <span>Total Revenue ({{ monthName }})</span>
    </v-card-title>

    <v-card-text
      v-show="isRevenueLoading"
      class="my-7"
    >
      <total-revenue-shimmer />
    </v-card-text>

    <v-card-text
      v-show="!isRevenueLoading && monthRevenue > 0"
      class="my-7"
    >
      <div class="d-flex align-center">
        <h1 class="text-3xl font-weight-semibold">
          Ksh {{ monthRevenue | formatCurrency }}
        </h1>

        <div class="d-flex align-center mb-n3">
          <v-icon
            size="40"
            :style="[percentageDifference > 0 ? {'color' : '#56CA00'} : {'color' : '#E53935'}]"
          >
            {{ differenceIcon }}
          </v-icon>
          <span
            v-show="percentageDifference !== 0"
            class="text-base font-weight-medium ms-n2"
            :style="[percentageDifference > 0 ? {'color' : '#56CA00'} : {'color' : '#E53935'}]"
            :class="{ 'success--text': percentageDifference > 0, 'red darken-1--text': percentageDifference < 0}"
          >{{ percentageDifference | positive }}%</span>
        </div>
      </div>

      <h4 class="mt-2 font-weight-medium">
        Compared to Ksh {{ previousMonthRevenue | formatCurrency }} on {{ previousMonthName }}
      </h4>
    </v-card-text>

    <v-card-text v-show="!isRevenueLoading && monthRevenue > 0">
      <apexchart
        ref="chart"
        type="donut"
        :options="chartOptions"
        :series="chartOptions.series"
      ></apexchart>
    </v-card-text>

    <v-card-text v-show="!isRevenueLoading && monthRevenue === 0">
      <p class="ml-4 font-weight-light">
        There is no enough data. Statistics will be displayed when enough data is gathered
      </p>
    </v-card-text>

    <v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mdiMenuUp, mdiMenuDown } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'
import TotalRevenueShimmer from '@/components/partials/shimmers/TotalRevenueShimmer.vue'

export default {
  components: {
    apexchart: VueApexCharts,
    TotalRevenueShimmer,
  },
  setup() {
    return {
      icons: { mdiMenuUp, mdiMenuDown },
    }
  },
  filters: {
    positive(value) {
      return Math.abs(value)
    },
  },

  data() {
    return {
      isRevenueLoading: true,
      monthName: '',
      previousMonthName: '',
      monthRevenue: '',
      previousMonthRevenue: '',
      chartOptions: {
        series: [],
        labels: [],
        chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
      },

    }
  },
  computed: {
    differenceIcon() {
      if (this.monthRevenue > this.previousMonthRevenue) {
        return this.icons.mdiMenuUp
      }
      if (this.monthRevenue < this.previousMonthRevenue) {
        return this.icons.mdiMenuDown
      }

      return null
    },
    percentageDifference() {
      return (this.monthRevenue - this.previousMonthRevenue) / 100
    },
  },
  mounted() {
    this.monthName = moment().subtract(1, 'months').startOf('month').format('MMMM')
    this.previousMonthName = moment().subtract(2, 'months').startOf('month').format('MMMM')
    this.getRevenue()
  },
  methods: {
    getRevenue() {
      this.isRevenueLoading = true
      axios
        .get('statistics/previous-month-revenue-statistics')
        .then(response => {
          this.monthRevenue = response.data.previousMonthRevenue.value
          this.previousMonthRevenue = response.data.monthBeforeLastMonthRevenue
          const { previousMonthStationRevenue } = response.data
          const chartLabels = []
          const chartValues = []
          previousMonthStationRevenue.forEach(element => {
            chartLabels.push(element.name)
            chartValues.push(element.value)
          })
          this.chartOptions = {
            ...this.chartOptions,
            ...{
              labels: chartLabels,
              series: chartValues,
            },
          }

          this.isRevenueLoading = false
        })
        .catch(error => {
          this.isRevenueLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
