<template>
  <v-card min-height="475">
    <v-card-title>
      Monthly Revenue
      <v-spacer></v-spacer>
    </v-card-title>
    <monthly-revenue-shimmer v-show="isRevenueLoading" />
    <div id="chart">
      <apexchart
        v-show="!isRevenueLoading & chartOptions.xaxis.categories.length > 0"
        ref="chart"
        type="bar"
        height="400"
        :options="chartOptions"
        :series="chartOptions.series"
      ></apexchart>
    </div>
    <v-card-text v-show="!isRevenueLoading && chartOptions.xaxis.categories.length === 0">
      <p class="ml-4 font-weight-light">
        There is no enough data. Statistics will be displayed when enough data is gathered
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import MonthlyRevenueShimmer from '@/components/partials/shimmers/MonthlyRevenueShimmer.vue'

export default {
  components: {
    apexchart: VueApexCharts,
    MonthlyRevenueShimmer,
  },
  data() {
    return {
      isRevenueLoading: true,
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [],
        },
        series: [{
          name: 'Revenue (Ksh)',
          data: [],
        }],
      },

    }
  },
  mounted() {
    this.getRevenue()
  },
  methods: {
    getRevenue() {
      this.isRevenueLoading = true
      axios
        .get('statistics/monthly-revenue')
        .then(response => {
          const { data } = response
          const chartLabels = []
          const chartValues = []

          data.forEach(element => {
            chartValues.push(element.value)
            chartLabels.push(element.name)
          })

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'Revenue (Ksh)',
                data: chartValues,
              }],
            },
          }
          this.isRevenueLoading = false
        })
        .catch(error => {
          this.isRevenueLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
  },

}
</script>

<style>

</style>
