<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <dashboard-statistics-card></dashboard-statistics-card>
    </v-col>
    <v-col cols="4">
      <dashboard-card-total-earning />
    </v-col>
    <v-col cols="8">
      <monthly-earning />
    </v-col>
    <v-col cols="12">
      <main-meter-readings />
    </v-col>
    <v-col cols="12">
      <unresolved-transactions></unresolved-transactions>
    </v-col>
    <v-col cols="12">
      <faulty-meters />
    </v-col>
  </v-row>
</template>

<script>

import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import UnresolvedTransactions from './UnresolvedTransactionsCard.vue'
import DashboardCardTotalEarning from './TotalRevenueCurrentMonthCard.vue'
import MonthlyEarning from './MonthlyRevenueCard.vue'
import MainMeterReadings from './MainMeterReadings.vue'
import FaultyMeters from './FaultyMetersCard.vue'

export default {
  components: {
    DashboardStatisticsCard,
    UnresolvedTransactions,
    DashboardCardTotalEarning,
    MonthlyEarning,
    MainMeterReadings,
    FaultyMeters,
  },
}
</script>
