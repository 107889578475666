<template>
  <v-card :height="200">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Welcome {{ userInfo.name.split(' ')[0] }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-medium text--primary me-1">Here are some some statistics</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row v-show="statisticsDataLoading">
        <statistics-shimmer
          v-for="item in [0, 1, 2, 3]"
          :key="item"
        />
      </v-row>
      <v-row v-show="!statisticsDataLoading">
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="3"
        >
          <v-card
            class="d-flex align-center"
            width="95%"
            hover
            ripple
            flat
            :to="data.route ? { name: data.route } : undefined"
            @click="data.href ? onStatisticsClick(data.href) : data.title ==='Revenue' ? showRevenue = !showRevenue : undefined"
          >
            <v-avatar
              size="54"
              :color="resolveStatisticsIconVariation(data.title).color"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ resolveStatisticsIconVariation(data.title).icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                {{ data.title }}
              </p>
              <h3 class="text-xl font-weight-semibold">
                <span v-if="data.title ==='Revenue' && !showRevenue">
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiEyeOff }}
                  </v-icon>
                </span>
                <span v-else>
                  {{ data.total }}
                </span>
              </h3>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  mdiAccountMultipleOutline, mdiTableAlert, mdiDotsVertical, mdiTable, mdiCashMultiple, mdiEyeOff,
} from '@mdi/js'
import StatisticsShimmer from '@/components/partials/shimmers/StatisticsShimmer.vue'

export default {
  components: { StatisticsShimmer },
  data: () => ({
    statisticsData: [],
    statisticsDataLoading: true,
    showRevenue: false,
  }),
  computed: {
    ...mapGetters(['userInfo']),
  },
  setup() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Customers') return { icon: mdiAccountMultipleOutline, color: 'success' }
      if (data === 'Meters') return { icon: mdiTable, color: 'warning' }
      if (data === 'Faulty Meters') return { icon: mdiTableAlert, color: 'error' }
      if (data === 'Revenue') return { icon: mdiCashMultiple, color: 'info' }

      return { icon: mdiAccountMultipleOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiAccountMultipleOutline,
        mdiTable,
        mdiTableAlert,
        mdiCashMultiple,
        mdiEyeOff,
      },
    }
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      this.statisticsDataLoading = true
      axios
        .get('statistics')
        .then(response => {
          this.statisticsData = [
            {
              title: 'Customers',
              total: response.data.users,
              route: 'customers',
            },
            {
              title: 'Meters',
              total: response.data.meters,
              route: 'meters',
            },
            {
              title: 'Revenue',
              total: `Ksh ${this.$options.filters.formatCurrency(response.data.revenue)}`,
            },
            {
              title: 'Faulty Meters',
              total: response.data.faulty_meters,
              route: null,
              href: '#faulty-meters',
            },
          ]
          this.statisticsDataLoading = false
        })
        .catch(error => {
          this.statisticsDataLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    onStatisticsClick(href) {
      this.$vuetify.goTo(href, {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      })
    },
  },
}
</script>
